export default defineNuxtRouteMiddleware(async (to, from) => {
    const order = useOrder()
    const { get } = useOFetchCustom(`orders/${to.params.order}/show`)

    try {
        await new Promise((resolve, reject) => {
            get(undefined, {
                onSuccess: (response: any) => {
                    order.value = response.data
                    resolve(response)
                },
                onError: (error: any) => {
                    reject(error)
                },
            })
        })
    } catch (error: any) {
        if (error.status == 403) {
            return navigateTo("/store")
        }
    }
})
